import {inject, injectable} from 'inversify';
import {map, Observable} from 'rxjs';
import {GlobalContainerTypes, Http} from '@innowise-group/core';
import {HistoryApi, HistoryProcessedResponse, HistoryResponse, RawFiltersParams} from './history-api.types';
import {HistoryContainerTypes} from '../history-ioc.types';
import {HistoryNormalization} from '../history-normalization';

@injectable()
class HistoryApiService implements HistoryApi {
  public static readonly type = HistoryContainerTypes.History;

  @inject(GlobalContainerTypes.Http) private http: Http;
  @inject(HistoryContainerTypes.HistoryNormalization) private historyNormalization: HistoryNormalization;

  public getChangeHistoryList(filters: RawFiltersParams): Observable<HistoryProcessedResponse> {
    const params = this.createUrlSearchParams(filters);

    return this.http.GET<HistoryResponse>(`/logs/logs-per-two-months/?${params}`).pipe(
      map(({count, results}) => ({
        count,
        logs: results.map(this.historyNormalization.normalizeHistoryFromApi),
      })),
    );
  }

  public getChangeHistoryListToday(filters: RawFiltersParams): Observable<HistoryProcessedResponse> {
    const params = this.createUrlSearchParams(filters);

    return this.http.GET<HistoryResponse>(`/logs/logs-per-day/?${params}`).pipe(
      map(({count, results}) => ({
        count,
        logs: results.map(this.historyNormalization.normalizeHistoryFromApi),
      })),
    );
  }

  public getChangeHistoryListForWeek(filters: RawFiltersParams): Observable<HistoryProcessedResponse> {
    const params = this.createUrlSearchParams(filters);

    return this.http.GET<HistoryResponse>(`/logs/logs-per-week/?${params}`).pipe(
      map(({count, results}) => ({
        count,
        logs: results.map(this.historyNormalization.normalizeHistoryFromApi),
      })),
    );
  }

  public getChangeHistoryListForMonth(filters: RawFiltersParams): Observable<HistoryProcessedResponse> {
    const params = this.createUrlSearchParams(filters);

    return this.http.GET<HistoryResponse>(`/logs/logs-per-month/?${params}`).pipe(
      map(({count, results}) => ({
        count,
        logs: results.map(this.historyNormalization.normalizeHistoryFromApi),
      })),
    );
  }

  private createUrlSearchParams(filters: RawFiltersParams): string {
    const params = new URLSearchParams();
    const normalizedFilters = this.historyNormalization.normalizeHistoryRawFilters(filters);

    Object.keys(normalizedFilters).forEach((key) => {
      if (!normalizedFilters[key]) return;
      params.set(key, normalizedFilters[key]);
    });
    return params.toString();
  }
}

export default HistoryApiService;
