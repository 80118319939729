import React, {ComponentProps, useContext, useEffect, useImperativeHandle} from 'react';
import {useTranslation} from 'react-i18next';

import ReactFlow, {Background, Controls, ReactFlowProvider} from 'react-flow-renderer';
import {Button, Icon, Loader, Typography, useTheme} from '@innowise-group/ui-kit';
import {Can, Employee, EmployeeWorkspace, RequestOnPlan} from '@innowise-group/core';
import {subject} from '@casl/ability';

import * as Styled from './floor-plan.styles';
import {BookingWorkspaceModeFormValues} from '../booking-workspace-mode-form';
import {FloorData} from '../../pages/floor-details/use-plan-details.hook';
import {FloorsEventEmitterService} from '../../services/floors-event-emitter';
import {PlanElementView} from '../plan-element-vew';
import {SearchFloorValues} from '../search-floor-form';
import {WorkspaceView} from '../workspace-view';
import {useFloorDetails} from '../../pages/floor-details/use-floor-details.hook';
import {ScreenZoomContext} from '@shared/core/services/screen-zoom';

const nodeTypes = {
  rooms: PlanElementView,
  workspaces: WorkspaceView,
  stairs: PlanElementView,
  balconies: PlanElementView,
  lifts: PlanElementView,
  doors: PlanElementView,
};

export interface FloorPlanHandler {
  handleAddNewWorkspace: (isVirtual: boolean) => void;
  handleUploadPlan: () => void;
  handleSearchFloorSubmit: (data: SearchFloorValues) => void;
  handleBookingWorkspaceMode: (data: BookingWorkspaceModeFormValues) => void;
  handleBookWorkspace: () => void;
  abortChanges: () => void;
  saveChanges: () => void;
  downloadCSV: () => void;
  downloadPDF: () => void;
  downloadXLSX: () => void;
  handleSecondPlanFloorChange: () => void;
}
interface FloorPlanProps {
  floorId: string;
  requestData?: RequestOnPlan;
  isEditMode: boolean;
  setEmployees: React.Dispatch<React.SetStateAction<Employee[]>>;
  setFloorData?: React.Dispatch<React.SetStateAction<FloorData>>;
  setIsPlanExists?: React.Dispatch<React.SetStateAction<boolean>>;
  isShowSecondPlan: boolean;
  onFocusClick: (floor: FloorData) => void;
  onLoadingStateChanged?: (state: boolean) => void;
  focus: boolean;
  showNotifications: boolean;
  focusWorkspace?: Pick<EmployeeWorkspace, 'id' | 'floorId'>;
  Map: React.FC<ComponentProps<typeof ReactFlow>>;
  selectedBookingDetails?: BookingWorkspaceModeFormValues;
}

export const FloorContext = React.createContext<{floorsEventEmitter: FloorsEventEmitterService; floor: FloorData}>(
  null,
);
export const ShowOnPlanRequest = React.createContext<
  RequestOnPlan & {focusWorkspace?: Pick<EmployeeWorkspace, 'id' | 'floorId'>}
>(null);

const FloorPlan = React.forwardRef<FloorPlanHandler, FloorPlanProps>(
  (
    {
      floorId,
      isEditMode,
      requestData,
      setEmployees,
      setFloorData,
      isShowSecondPlan = false,
      onFocusClick,
      onLoadingStateChanged,
      focus,
      showNotifications,
      setIsPlanExists,
      focusWorkspace,
      Map,
      selectedBookingDetails,
    },
    ref,
  ) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {screenZoom} = useContext(ScreenZoomContext);

    const {
      flowElements,
      floor,
      handleAddNewWorkspace,
      isPlanLoading,
      isPlanExists,
      reactFlowWrapper,
      onLoad,
      onDrop,
      onDragOver,
      handleUploadPlan,
      onNodeDragStop,
      handleSearchFloorSubmit,
      handleBookingWorkspaceMode,
      handleBookWorkspace,
      abortChanges,
      saveChanges,
      selectable,
      handleSecondPlanFloorChange,
      floorsEventEmitter,
    } = useFloorDetails(
      floorId || requestData?.idFloor || focusWorkspace?.floorId,
      !isEditMode,
      setEmployees,
      showNotifications,
    );
    const loadedFloorId = floor?.floor;

    useEffect(() => {
      if (typeof onLoadingStateChanged === 'function') {
        onLoadingStateChanged(isPlanLoading);
      }
    }, [isPlanLoading, onLoadingStateChanged]);

    useEffect(() => {
      if (floor?.floor && setFloorData) {
        setFloorData(floor);
      }
    }, [floor, setFloorData]);

    useEffect(() => {
      if (loadedFloorId === floorId && selectedBookingDetails) {
        setTimeout(() => {
          handleBookingWorkspaceMode(selectedBookingDetails);
        }, 0);
      }
    }, [floorId, loadedFloorId, handleBookingWorkspaceMode, selectedBookingDetails]);

    useEffect(() => {
      if (setIsPlanExists) {
        setIsPlanExists(isPlanExists);
      }
    }, [setIsPlanExists, isPlanExists]);

    useImperativeHandle(
      ref,
      () => ({
        handleAddNewWorkspace,
        handleUploadPlan,
        handleSearchFloorSubmit,
        handleSecondPlanFloorChange,
        handleBookingWorkspaceMode,
        handleBookWorkspace,
        abortChanges,
        saveChanges,
        downloadCSV: () => floorsEventEmitter.emit('downloadCSVReport'),
        downloadPDF: () => floorsEventEmitter.emit('downloadPDFReport'),
        downloadXLSX: () => floorsEventEmitter.emit('downloadXLSXReport'),
      }),
      [
        abortChanges,
        floorsEventEmitter,
        handleAddNewWorkspace,
        handleBookWorkspace,
        handleBookingWorkspaceMode,
        handleSearchFloorSubmit,
        handleSecondPlanFloorChange,
        handleUploadPlan,
        saveChanges,
      ],
    );

    const onMouseLeaveHandler = () => {
      if (isShowSecondPlan) {
        floorsEventEmitter.emit('list');
      }
    };

    const onFloorPlanClickHandler = () => {
      onFocusClick(floor);
    };

    if (isPlanLoading) {
      return (
        <Styled.FloorPlanContainer>
          <Styled.Overlay>
            <Loader />
          </Styled.Overlay>
        </Styled.FloorPlanContainer>
      );
    }

    return (
      <FloorContext.Provider value={{floorsEventEmitter, floor}}>
        <ShowOnPlanRequest.Provider value={{...requestData, focusWorkspace}}>
          <Styled.FloorPlanContainer onClick={onFloorPlanClickHandler} onMouseLeave={onMouseLeaveHandler}>
            {isPlanExists && !isPlanLoading ? (
              <ReactFlowProvider>
                {isShowSecondPlan && <Styled.Address type="h3">{floor.fullAddress}</Styled.Address>}
                <Styled.ReactFlowWrapper
                  ref={reactFlowWrapper}
                  selectable={selectable}
                  focus={isShowSecondPlan && focus}
                  screenZoom={screenZoom}
                >
                  <Map
                    elements={flowElements}
                    nodeTypes={nodeTypes}
                    selectNodesOnDrag={false}
                    nodesConnectable={false}
                    onDragOver={onDragOver}
                    onDrop={onDrop}
                    onLoad={onLoad}
                    onNodeDragStop={onNodeDragStop}
                    maxZoom={2}
                    minZoom={0.1}
                  >
                    <Background color={theme.palette.general.darkGrey[100]} gap={16} />
                    <Controls showInteractive={false} />
                  </Map>
                </Styled.ReactFlowWrapper>
              </ReactFlowProvider>
            ) : (
              !isPlanExists &&
              !isPlanLoading && (
                <Styled.Overlay>
                  <Icon type="architectural_plan" size={100} />
                  <Typography>{t('pages.floors.noPlan')}</Typography>
                  <Can I="CREATE-PLAN" this={subject('FLOOR', {id: floorId})}>
                    <Button onClick={handleUploadPlan} icon="u_arrow-up">
                      {t('buttons.uploadPlan')}
                    </Button>
                  </Can>
                </Styled.Overlay>
              )
            )}
          </Styled.FloorPlanContainer>
        </ShowOnPlanRequest.Provider>
      </FloorContext.Provider>
    );
  },
);

export default React.memo(FloorPlan);
