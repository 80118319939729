import {createContext, useEffect, useState} from 'react';
import {useWindowDimensions} from '@shared/core/hooks';
import {MAX_MOBILE_DEVICE_WIDTH, STANDARD_SCREEN_WIDTH} from '@shared/core/constants';
import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle<{screenZoom?: number}>`
  body {
    zoom: ${({screenZoom}) => screenZoom}; 
  }
`;

export const ScreenZoomContext = createContext<{screenZoom: number}>(null);

const ScreenZoomProvider = ({children}) => {
  const [screenZoom, setScreenZoom] = useState(1);
  const {documentWidth, documentHeight} = useWindowDimensions();

  useEffect(() => {
    const screenZoom = (() => {
      const isPortrait = documentWidth < documentHeight;
      const isMobile = isPortrait
        ? documentWidth <= MAX_MOBILE_DEVICE_WIDTH
        : documentHeight <= MAX_MOBILE_DEVICE_WIDTH;

      if (isMobile) {
        return 1;
      }

      const zoom = Number((documentWidth / STANDARD_SCREEN_WIDTH).toFixed(1));

      if (zoom > 1) {
        return 1;
      } else if (zoom < 0.8) {
        return 0.8;
      }

      return zoom;
    })();

    setScreenZoom(screenZoom);
  }, [documentWidth, documentHeight]);

  return (
    <ScreenZoomContext.Provider value={{screenZoom}}>
      {children}
      <GlobalStyles screenZoom={screenZoom} />
    </ScreenZoomContext.Provider>
  );
};

export default ScreenZoomProvider;
