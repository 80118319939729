import {inject, injectable} from 'inversify';
import {EmployeesNormalization, GlobalContainerTypes, Language} from '@innowise-group/core';
import {HistoryNormalization} from './history-normalization.types';
import {HistoryContainerTypes} from '../history-ioc.types';
import {ActionHistoryType, FiltersParams, HistoryResponseResults, RawFiltersParams} from '../history-api';
import {ChangeHistory} from '../../models/change-history.model';

import {actionStatuses} from './action-statuses.constant';
import {pickBy} from 'lodash';

@injectable()
class HistoryNormalizationService implements HistoryNormalization {
  public static readonly type = HistoryContainerTypes.HistoryNormalization;

  @inject(GlobalContainerTypes.EmployeesNormalization) private employeesNormalization: EmployeesNormalization;
  @inject(GlobalContainerTypes.Language) private language: Language;

  constructor() {
    this.normalizeHistoryFromApi = this.normalizeHistoryFromApi.bind(this);
    this.normalizeHistoryActionFromApi = this.normalizeHistoryActionFromApi.bind(this);
    this.normalizeHistoryRawFilters = this.normalizeHistoryRawFilters.bind(this);
  }

  public normalizeHistoryFromApi(history: HistoryResponseResults): ChangeHistory {
    const {
      id,
      location,
      street,
      employee,
      room,
      created_at,
      location_eng,
      street_eng,
      action,
      workspace_number,
      initiator,
      status,
      office_id,
    } = history;

    const language = this.language.checkAppLanguage();

    const address = language
      ? this.normalizeOfficeAdress(location, street)
      : this.normalizeOfficeAdress(location_eng, street_eng);

    return {
      id: id && String(id),
      employee: this.employeesNormalization.normalizeEmployeeFromApi(employee),
      responsible: this.employeesNormalization.normalizeEmployeeFromApi(initiator),
      time: new Date(created_at),
      office: address,
      seat: workspace_number,
      room: {
        id: room?.id && String(room?.id),
        number: room?.number,
      },
      action: action && this.normalizeHistoryActionFromApi(action),
      status,
      office_id,
    };
  }

  public normalizeHistoryRawFilters(filters: RawFiltersParams): FiltersParams {
    const {locationId, officeId, employee, limit, offset} = filters;
    const processedFilters = {
      room__floor__office__location__id: locationId,
      room__floor__office_id: officeId,
      employee,
      limit,
      offset,
    };
    return pickBy(processedFilters, (value) => Boolean(value));
  }

  private normalizeHistoryActionFromApi(action: ActionHistoryType): string {
    if (actionStatuses[action]) return actionStatuses[action];
    return 'unknown';
  }

  private normalizeOfficeAdress(location: string | null, street: string | null): string | null {
    if (location && street) {
      return `${location}, ${street}`;
    }
    return null;
  }
}

export default HistoryNormalizationService;
