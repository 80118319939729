import {useState, useMemo, useCallback, KeyboardEvent} from 'react';
import {useTranslation} from 'react-i18next';
import {Icon} from '../icon';
import {DOTS, usePagination} from './use-pagination.hook';
import * as Styled from './pagination.styles';
import {withForm} from '../form';
import {useTheme} from '../theme-provider';

export interface PaginationProps {
  extended?: boolean;
  pageSize: number;
  totalCount: number;
  currentPage: number;
  resetPageOnChangePageSize?: boolean;
  onPageChange: (page: number) => void;
  setPageSize?: (page: number) => void;
  dropDownDirection?: 'bottom' | 'top';
}

const options = [
  {title: '10', value: '10'},
  {title: '20', value: '20'},
  {title: '50', value: '50'},
];

const Pagination: React.FC<PaginationProps> = ({
  totalCount,
  pageSize,
  currentPage,
  onPageChange,
  setPageSize,
  extended = false,
  dropDownDirection,
  children,
  resetPageOnChangePageSize,
}) => {
  const pagination = usePagination({totalCount, pageSize, currentPage});
  const [pageValue, setPageValue] = useState('');
  const {t} = useTranslation();
  const theme = useTheme();

  const lastPage = useMemo(() => {
    return pagination.length > 0 ? Number(pagination[pagination?.length - 1]) : 1;
  }, [pagination]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return;

      const pageNumber = Number(pageValue);
      if (pageNumber) {
        if (pageNumber >= lastPage) {
          return onPageChange(lastPage);
        }
        onPageChange(pageNumber);
      }
    },
    [pageValue, onPageChange, lastPage],
  );

  const transformValue = (value) => options?.find((item) => item?.value === value)?.title;

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const pageValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageValue(event.target.value.replace(/\D|^0+/g, ''));
  };

  const selectValueChangeHandler = (value: string | string[]) => {
    setPageSize(Number(value));

    if (resetPageOnChangePageSize) {
      onPageChange(1);
    }
  };

  return (
    <Styled.PaginationContainer isHidden={totalCount <= 10} style={{flexWrap: 'wrap'}}>
      <Styled.PaginationButton type="button" onClick={onPrevious} disabled={currentPage === 1}>
        <Icon type="u_angle-left-b" fill={theme.palette.generalAlt.font.tertiary} isPointer />
      </Styled.PaginationButton>
      {(pagination || []).map((page, i) => {
        const pageNumber = Number(page);
        const onPageClick = () => {
          onPageChange(pageNumber);
          setPageValue('');
        };
        if (page === DOTS) {
          return (
            <Styled.PaginationItem key={i} className="dots">
              &#8230;
            </Styled.PaginationItem>
          );
        }
        return (
          <Styled.PaginationItem key={i} panelActive={currentPage === pageNumber} onClick={onPageClick}>
            {page}
          </Styled.PaginationItem>
        );
      })}
      <Styled.PaginationButton type="button" onClick={onNext} disabled={currentPage === lastPage}>
        <Icon type="u_angle-right-b" fill={theme.palette.generalAlt.font.tertiary} isPointer />
      </Styled.PaginationButton>
      {extended && (
        <Styled.PaginationContainer style={{flexWrap: 'nowrap'}}>
          <Styled.CountSelect
            defaultValue={String(pageSize)}
            options={options}
            value={String(pageSize)}
            onValueChange={selectValueChangeHandler}
            valueTransformer={transformValue}
            verticalDirection={dropDownDirection}
            withSearch={false}
            size="extra-small"
            isRedHeaderColor
          />
          {lastPage !== 1 && (
            <>
              <Styled.HelperText>{t('helperText.goToPage')}:</Styled.HelperText>
              <Styled.PageInput onKeyDown={onKeyDown} inputSize="small" value={pageValue} onChange={pageValueHandler} />
            </>
          )}
        </Styled.PaginationContainer>
      )}
      {children}
    </Styled.PaginationContainer>
  );
};

export default withForm(Pagination);
